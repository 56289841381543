@charset "utf-8";

/* ---------------------------------------------
	Our business
--------------------------------------------- */
.p-ourbusiness {
	&__content {
		@include mq(sm) {
			margin-top: 100px;
		}
		& > div {
			margin-top: vwsp(120);
			position: relative;
			@include mq(sm) {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				margin-top: 30px;
			}
			div {
				width: 100%;
				@include mq(sm) {
					width: calc(100% - 190px);
				}
				p {
					&:first-of-type {
						font-family: $font-family-ttl-en;
						font-weight: $font-weight-bolder;
						color: $main-color3;
						line-height: 1;
						margin-bottom: 0;
						@include mq_down(sm) {
							@include absolute_top_left($top: -30px);
						}
					}
					&:last-of-type {
						line-height: 1.6;
					}
				}
				.c-head__bar {
					margin-top: 1em;
				}
			}
		}
		figure {
			width: 160px;
			height: 120px;
			padding-top: 30px;
			text-align: center;
			background-image: repeating-linear-gradient(0deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px), repeating-linear-gradient(90deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px), repeating-linear-gradient(180deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px),
				repeating-linear-gradient(270deg, #999, #999 1px, transparent 1px, transparent 2px, #999 3px);
			background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
			background-position: 0 0, 0 0, 100% 0, 0 100%;
			background-repeat: no-repeat;

			img {
				width: 36%;
				height: auto;
				vertical-align: middle;
			}
			&:nth-of-type(3) {
				img {
					width: 57px;
				}
			}
			&:nth-of-type(3) {
				img {
					width: 58px;
				}
			}
			&:nth-of-type(3) {
				img {
					width: 60px;
				}
			}
		}
	}
}
