@charset "utf-8";

/* ---------------------------------------------
	Main
--------------------------------------------- */
.l-wrapper {
	overflow: hidden;
	position: relative;
}

main {
	min-height: 600px;
	margin-top: 100px;

	@include mq_down(sm) {
		margin-top: vwsp(82);
	}

	@include mq(xl) {
		margin-top: 140px;
	}

	@include mq(xxl) {
		margin-top: 160px;
	}

	&.nomargin {
		margin-top: 0;
	}

	p,
	ul,
	ol,
	dl {
		@include font-size(15);
		line-height: 2.4;
	}

	p {
		margin-bottom: 2em;
	}
}

section,
aside {
	position: relative;
	padding-top: vwsp(100);
	padding-bottom: vwsp(100);
	z-index: 0;

	@include mq(xs) {
		padding-top: 97px;
		padding-bottom: 97px;
	}
	@include mq(lg) {
		padding-top: 160px;
		padding-bottom: 160px;
	}
}

section.w {
	color: $main-color2;
}

.l-container {
	width: calc(100% - 12vw);
	max-width: $width-main;
	background-color: transparent;
	margin: auto;
	position: relative;
	.l-content {
		&.flex {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			@include mq_down(lg) {
				display: block;
			}
		}

		&__left {
			width: 100%;
			margin-bottom: vwsp(50);

			@include mq(xs) {
				margin-bottom: 97px;
			}

			@include mq(xl) {
				width: 25%;
				margin-bottom: 0;
			}
		}
		&__right {
			width: 100%;

			@include mq(xl) {
				width: 62.5%;
			}
		}
	}
}

main {
	.l-container {
		.l-content.flex {
			@include mq(xxl) {
				justify-content: start;
			}
			.l-content__right {
				@include mq(xl) {
					padding-right: 5%;
				}

				@include mq(xxl) {
					margin-left: 12.5%;
					padding-right: 8%;
				}
				&.wide {
					padding-right: 0;
				}
			}
		}
	}
}
