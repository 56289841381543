@charset "utf-8";

/* ---------------------------------------------
	Area -- link
--------------------------------------------- */
.c-area__link {
	width: 100%;
	height: auto;
	background: $main-bg-color2;

	ul {
		@include mq(xs) {
			width: 350px;
			margin: auto;
		}
		@include mq() {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			margin: 0;
		}
		li {
			width: 100%;
			max-width: 350px;
			height: 0;
			padding-bottom: 91%;
			font-weight: $font-weight-bold;
			line-height: 1.2;
			position: relative;
			margin-bottom: 60px;
			@include mq(xs) {
				height: 320px;
				padding-bottom: 0;
			}
			@include mq() {
				width: 25%;
				max-width: 320px;
				height: 0;
				padding-bottom: 26%;
				margin-bottom: 0;
			}
			@include mq(xl) {
				height: 320px;
				padding-bottom: 0;
			}
			&:last-child {
				@include mq_down() {
					margin-bottom: 0;
				}
			}
			div {
				width: 100%;
				height: 0;
				padding-bottom: 75%;
				margin-bottom: 7%;
				overflow: hidden;
				img {
					width: 100%;
					margin-bottom: vwsize(10);
					transition: all 0.3s;
				}
			}
			p {
				font-size: vwsp(18);
				@include mq(xs) {
					@include font-size(18);
				}
				@include mq() {
					font-size: vwsize(22);
				}
				@include mq(xl) {
					@include font-size(22);
				}
				span {
					color: $accent-color;
					font-family: $font-family-ttl-en;
					font-weight: $font-weight-bolder;
					font-size: 85%;
					letter-spacing: 0;
					@include break;
				}
			}
			a {
				@include over;
				z-index: 1;

				&:hover ~ div img {
					transform: scale(1.2);
				}
			}
		}
	}
}

/* ---------------------------------------------
	Area -- contact
--------------------------------------------- */
.c-area__contact {
	& > p {
		font-size: vwsp(16);
		@include mq(xs) {
			@include font-size(14);
		}
	}
	&__type {
		@include mq() {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		li {
			width: 100%;
			@include mq() {
				margin-top: vwsize(60);
			}
			p {
				width: 100%;
				font-size: vwsp(17);
				font-weight: $font-weight-bold;
				margin-top: 40px;
				margin-bottom: 10px;
				@include mq(xs) {
					@include font-size(15);
				}
			}
			a {
				width: 100%;
				height: 40px;
				background: $main-color;
				font-family: $font-family-ttl-en;
				font-weight: $font-weight-bolder;
				font-size: vwsp(17);
				color: $main-color2;
				line-height: 1;
				padding-top: 15px;
				padding-right: 1em;
				padding-left: 1em;
				@include bg("n", no, left 1em center);
				position: relative;
				@include mq(xs) {
					@include font-size(15);
				}
			}
			&:nth-child(1) {
				@include mq() {
					width: 47.5%;
				}
				a {
					background-image: url($imgDir + "common/icon_tel.svg");
					background-size: 26px auto;
					font-size: vwsp(18);
					text-align: right;
					@include mq(xs) {
						@include font-size(16);
					}
				}
			}
			&:nth-child(2) {
				@include mq() {
					width: 47.5%;
				}
				a {
					background-image: url($imgDir + "common/icon_email.svg");
					background-size: 28px auto;
					font-family: $font-family-base;
					font-size: vwsp(19);
					font-weight: $font-weight-bolder;
					padding-top: 12px;
					text-align: right;
					@include mq(xs) {
						@include font-size(18);
						padding-top: 10px;
					}
				}
			}
			&:nth-child(3) {
				margin-top: vwsp(70);
				@include mq() {
					margin-top: 110px;
				}
				p {
					@include mq_down {
						line-height: 1.4;
						height: 45px;
					}
					& > span {
						font-size: 75%;
						margin-top: 0.5em;
						display: block;
						@include mq() {
							float: right;
						}
					}
				}
				a {
					letter-spacing: 0;
				}
			}
		}
	}
}
