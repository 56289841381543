@charset "utf-8";

/* ---------------------------------------------
	List -- disc
--------------------------------------------- */
.c-list__disc {
	list-style: none;
	margin-left: 1.2em;
	text-indent: -1em;
	margin-top: -1.6em;
	li {
		&::before {
			content: "・";
			width: 0.5em;
			margin-right: 0.3em;
		}
	}
}

/* ---------------------------------------------
	List -- table
--------------------------------------------- */
.c-list__table {
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.5em;
		background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
	}
	@include mq(sm) {
		display: flex;
		flex-flow: row wrap;
	}
	dt {
		color: $accent-color2;
		padding-bottom: 1em;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1.5em;
			background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
		}
		@include mq(sm) {
			width: 12em;
		}
	}
	dd {
		padding-bottom: 1em;
		@include mq(sm) {
			width: calc(100% - 12em);
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1.5em;
				background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
			}
		}
		li {
			padding-bottom: 1em;
			@include mq(sm) {
				&::before {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 1em;
					background-image: repeating-linear-gradient(90deg, #231815 0px, #231815 2px, #fff 2px, #fff 4px);
				}
				&:first-of-type {
					&::before {
						display: none;
					}
				}
			}
			&:last-of-type {
				padding-bottom: 0;
				margin-bottom: -0.5em;
			}
		}
	}
}
