@charset "utf-8";

/* ------------------------------
	Navigation -- global
------------------------------ */
.c-gnav__wrap {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 0;
	background: rgba($main-color, 0.95);
	visibility: hidden;
	opacity: 0;
	overflow-y: hidden;
	scrollbar-face-color: $scrollbar-face-color;
	scrollbar-track-color: $scrollbar-face-color;
	-webkit-overflow-scrolling: touch;
	transition: all 0.8s ease-in-out;
	z-index: 0;

	.open & {
		height: 100vh;
		visibility: visible;
		overflow-y: scroll;
		opacity: 1;
		z-index: 3;
	}
	.c-btn__contactform {
		margin: auto;
	}
	address {
		color: $main-color2;
		margin-top: 60px;
		margin-bottom: 160px;
		text-align: center;
		@include mq(lg) {
			margin-top: 132px;
		}
	}
}

.c-gnav__logo {
	width: 113px;
	height: 80px;
	margin-top: 80px;
	margin-right: auto;
	margin-left: auto;

	@include mq() {
		width: 144px;
		height: 102px;
		margin-top: 164px;
	}
}

.c-gnav {
	width: 113px;
	margin: 70px auto 72px;
	@include mq(lg) {
		display: flex;
		flex-flow: row wrap;
		width: calc(100% - 12vw);
		max-width: 650px;
		margin-top: 133px;
		margin-bottom: 40px;
	}

	li {
		width: auto;
		list-style: none;
		text-align: center;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 48px;

		a {
			display: block;
			color: $main-color2;
			font-weight: $font-weight-bold;
			line-height: 1.5;

			span {
				&:nth-of-type(1) {
					@include font-size(12);
				}

				&:nth-of-type(2) {
					@include break;
					@include font-size(14);
				}
			}

			&:hover,
			&.active {
				color: $link-hover-color;
				opacity: 1;
			}
		}
	}
}

/* ------------------------------
	Navigation -- button
------------------------------ */
.c-nav__btn {
	cursor: pointer;
	outline: 0;
	position: relative;
	z-index: 12;

	div {
		position: relative;
		width: vwsp(26);
		height: vwsp(26);
		outline: 0;

		@include mq(sm) {
			width: 42px;
			height: 42px;
		}

		span {
			display: block;
			width: 100%;
			height: 2px;
			background: $accent-color;
			position: absolute;
			left: 0;
			transition: all 0.25s;

			@include mq(sm) {
				height: 3px;
			}

			@include mq(xxl) {
				height: 4px;
			}

			&:nth-of-type(1) {
				top: 1px;

				@include mq(sm) {
					top: -1px;
				}

				@include mq(xl) {
					top: 0;
				}

				.l-header.open & {
					background: $main-color2;
					top: 50%;
					transform: rotate(45deg);
				}
			}

			&:nth-of-type(2) {
				top: 50%;
				margin-top: vwsp(1);

				@include mq(sm) {
					top: calc(50% - 2px);
					margin-top: 0;
				}
				@include mq(xl) {
					top: 50%;
				}
				@include mq(xxl) {
					top: calc(50% + 2px);
				}

				.l-header.open & {
					width: 0;
					left: 50%;
				}
			}

			&:nth-of-type(3) {
				bottom: -3px;

				@include mq(sm) {
					bottom: 0;
				}

				@include mq(xl) {
					bottom: -2px;
				}

				@include mq(xxl) {
					bottom: -8px;
				}

				.l-header.open & {
					background: $main-color2;
					top: 50%;
					transform: rotate(-45deg);
				}
			}
		}
	}
}
