@charset "utf-8";

/* ------------------------------
	Button -- contact form
------------------------------ */
.c-btn__contactform {
	display: block;
	width: 224px;
	height: 28px;
	font-family: $font-family-en;
	font-weight: $font-weight-bold;
	letter-spacing: 0.1em;
	line-height: 33px;
	@include font-size(14);
	background: $main-color3;
	text-align: center;
	margin: auto;
	@include mq(sm) {
		width: 280px;
	}
	@include mq(lg) {
		width: 320px;
		height: 40px;
		line-height: 45px;
	}
}
/* ------------------------------
	Button -- privacy policy
------------------------------ */
.c-btn__privacy {
	display: block;
	width: 222px;
	height: 16px;
	color: $main-color3;
	border-left: 1px solid $main-color3;
	border-right: 1px solid $main-color3;
	font-family: $font-family-en;
	font-weight: $font-weight-bold;
	@include font-size(13);
	letter-spacing: 0.1em;
	line-height: 16px;
	text-align: center;
	margin: 18px auto;

	@include mq(sm) {
		width: 280px;
	}

	@include mq(lg) {
		width: 320px;
		height: 20px;
		@include font-size(14);
		line-height: 24px;
	}
}

/* ------------------------------
	Link -- read more
------------------------------ */
.c-more {
	width: vwsize(560);
	max-width: 200px;
	height: 43px;
	@include bg("common/readmore.svg", no, left bottom 47%, 45% auto);
	text-indent: -200%;
	overflow: hidden;
	border-bottom: 1px solid $main-color;
	position: absolute;
	left: 0;
	bottom: 18px;
	@include mq_down(xs) {
		width: vwsp(170);
		min-width: vwsp(170);
		height: vwsp(43);
	}
	@include mq(sm) {
		width: vwsize(500);
		max-width: 240px;
	}
	&::after {
		display: block;
		content: "";
		width: 10px;
		height: 10px;
		border-top: 1px solid $main-color;
		border-right: 1px solid $main-color;
		transform: rotate(45deg);
		@include absolute_bottom_right($bottom: 35%, $right: 5px);
		@include mq_down(xs) {
			width: vwsp(10);
			height: vwsp(10);
		}
	}
	&.w {
		background-image: url($imgDir + "common/readmore_w.svg");
		border-bottom-color: $main-color2;
		&::after {
			border-top-color: $main-color2;
			border-right-color: $main-color2;
		}
	}
}

/* ------------------------------
	Button -- more(news)
------------------------------ */
.c-btn__more {
	display: none;
	width: 150px;
	height: 3em;
	text-align: center;
	padding-top: 0.5em;
	border: 1px solid $main-color;
	float: right;
	margin-top: 100px;
	&.active {
		display: block;
	}
}
