@charset "utf-8";

/* ---------------------------------------------
	Heading -- vertical
--------------------------------------------- */
.c-head__vertical {
	font-weight: $font-weight-bold;
	@include font-size(24);
	line-height: 1;
	margin-bottom: 2em;

	@include mq_down() {
		font-size: vwsp(24);
		margin-bottom: 4em;
	}
	@include mq(xl) {
		width: 1em;
		line-height: 1.5;
		@include font-size(26);
		margin: auto;
		writing-mode: vertical-rl;
	}
	span {
		color: $main-color5;
		font-size: 60%;
		@include mq_down(xl) {
			font-size: 55%;
			@include break;
		}

		@include mq(xl) {
			margin-top: 2em;
			vertical-align: 10%;
		}
	}
}
/* ---------------------------------------------
	Heading -- section
--------------------------------------------- */
.c-head__sc {
	font-family: $font-family-ttl-en;
	font-size: vwsp(35);
	line-height: vwsp(35);

	@include mq(sm) {
		font-size: vwsize(95);
		line-height: 1;
	}
	@include mq() {
		font-size: vwsize(80);
	}
	@include mq(lg) {
		@include font-size(50);
	}
	span {
		@include break;
		font-family: $font-family-base;
		font-weight: 500;
		font-size: vwsp(17);
		@include mq(sm) {
			font-size: vwsize(50);
			line-height: 1;
		}
		@include mq() {
			@include font-size(20);
		}
		@include mq(lg) {
			@include font-size(24);
		}
	}
	br {
		@include mq_down(xl) {
			display: none;
		}
	}
}

/* ---------------------------------------------
	Heading -- large
--------------------------------------------- */
.c-head__large {
	color: $accent-color;
	font-family: $font-family-mincho;
	font-size: vwsp(45);
	letter-spacing: 0;
	line-height: 1.3;
	margin-bottom: 1.5em;
	@include mq(sm) {
		@include font-size(43);
	}
	@include mq(lg) {
		@include font-size(40);
		line-height: 1.6;
	}
	@include mq(xl) {
		@include font-size(44);
	}
}

/* ---------------------------------------------
	Heading -- subtitle
--------------------------------------------- */
.c-head__sub {
	color: $accent-color;
	font-weight: $font-weight-bold;
	font-size: vwsp(16);
	margin-top: 3em;
	margin-bottom: 0.5em;
	@include mq(xs) {
		@include font-size(16);
	}
}

/* ---------------------------------------------
	Heading -- under bar
--------------------------------------------- */
.c-head__bar {
	height: 2em;
	color: $accent-color;
	font-weight: $font-weight-bold;
	font-size: vwsp(19);
	@include mq(xs) {
		@include font-size(17);
	}
	&::after {
		display: block;
		content: "";
		width: vwsp(25);
		height: 2px;
		margin-top: 4px;
		background: $accent-color;
	}
}
