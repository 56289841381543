@charset "utf-8";

/* ---------------------------------------------
	Key visual
--------------------------------------------- */
.c-keyvisual {
	width: 100vw;
	height: 57.5vw;
	@include bg("n", no, left 30% top, auto 100%);
	position: relative;

	@include mq(xs) {
		height: 45vw;
	}

	@include mq() {
		height: 30vw;
	}

	@include mq() {
		background-size: 100% auto;
	}

	@include mq(xxxl) {
		height: 480px;
	}

	.l-container {
		position: absolute;
		height: 16vw;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;

		@include mq() {
			height: 5vw;
		}

		@include mq(xxxl) {
			height: 120px;
		}
	}

	&__text {
		width: 100%;
		height: 100%;
		text-indent: -200%;
		overflow: hidden;
		margin-bottom: -2px;
		@include absolute_bottom_left;
		@include bg("n", no, lb, auto 16vw);
		@include mq() {
			background-size: auto 4.7vw;
		}
		@include mq(xxl) {
			background-size: auto 68px;
		}
	}

	#greetings & {
		background-image: url($imgDir + "keyvisual/greetings.jpg");
		&__text {
			background-image: url($imgDir + "keyvisual/greetings_txt.svg");
			@include mq_down() {
				background-size: auto 8vw;
			}
		}
	}

	#companyprofile & {
		background-image: url($imgDir + "keyvisual/companyprofile.jpg");
		&__text {
			background-image: url($imgDir + "keyvisual/companyprofile_txt.svg");
			@include mq_down() {
				background-image: url($imgDir + "keyvisual/companyprofile_txt_s.svg");
			}
		}
	}

	#ourbusiness & {
		background-image: url($imgDir + "keyvisual/ourbusiness.jpg");
		&__text {
			background-image: url($imgDir + "keyvisual/ourbusiness_txt.svg");
			@include mq_down() {
				background-image: url($imgDir + "keyvisual/ourbusiness_txt_s.svg");
			}
		}
	}

	#contact & {
		background-image: url($imgDir + "keyvisual/contact.jpg");
	}

	#news & {
		background-image: url($imgDir + "keyvisual/news.jpg");
	}
}
