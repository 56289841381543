@charset "utf-8";

/* ---------------------------------------------
	Home
--------------------------------------------- */
.p-top {
	.l-content__left {
		.c-head__sc {
			@include mq_down(xl) {
				margin-bottom: 100px;
			}
		}
	}
	.l-content__right {
		@include mq_down(xl) {
			padding-bottom: 150px;
		}
	}
	.l-content {
		position: relative;
		z-index: 0;
		h2,
		h3,
		p {
			position: relative;
			z-index: 1;
		}
	}
	h3 {
		font-family: $font-family-mincho;
		font-weight: 500;
		font-size: vwsp(28);
		@include mq(xs) {
			font-size: vwsize(86);
		}
		@include mq() {
			@include font-size(36);
		}
	}
	//- HOME > メインビジュアル
	&__mainvisual {
		&__bg {
			width: 100%;
			height: 0;
			padding-bottom: 50%;
			@include bg("home/mainvisual.jpg", no, left 55% bottom 20px, 190% auto);
			position: relative;
			@include mq_down(xs) {
				height: vwsp(294);
				padding-bottom: 0;
			}
			@include mq(xl) {
				padding-bottom: 0;
				height: vwsize(800);
				max-height: 800px;
			}
			@include mq(xxl) {
				background-size: 160% auto;
				background-position: left 55% bottom;
			}
			&::before {
				content: "";
				display: block;
				width: 80%;
				max-width: $width-main;
				height: 0;
				padding-bottom: 35%;
				@include bg("home/mainvisual_window.svg", no, cc, contain);
				opacity: 0.8;
				@include centering;
				mix-blend-mode: color-burn;
				z-index: 3;
			}
		}
		.l-container {
			padding-top: 80px;
			padding-bottom: 160px;
			h2 {
				font-weight: $font-weight-bold;
			}
			p {
				font-family: $font-family-ttl-en;
				font-weight: $font-weight-bolder;
				line-height: 1.5;
				margin-top: -3em;
				@include mq_down(xs) {
					font-size: vwsp(16);
				}
			}
			.c-more {
				bottom: 80px;
			}
		}
	}
	//- HOME > GREETINGS
	&__greetings {
		position: relative;
		height: 0;
		padding-bottom: vwsp(630);
		@include bg("home/bg_greetings_s.jpg", no, cb, cover);
		overflow: hidden;
		@include mq(xs) {
			padding-top: 70px;
			padding-bottom: 0;
			height: vwsize(2222);
		}
		@include mq(sm) {
			height: vwsize(1744);
			padding-bottom: 0;
		}
		@include mq() {
			height: 800px;
			padding-top: 100px;
		}
		@include mq(xl) {
			background-image: url($imgDir + "home/bg_greetings.jpg");
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($main-color2, 0.2);
			@include centering;
			z-index: -1;

			@include mq() {
				width: vwsize(2000);
				max-width: 565px;
			}
		}
		.l-container {
			height: 0;
			padding-bottom: 100%;
			padding-top: 10%;
			text-align: center;
			z-index: 1;
			@include mq(xs) {
				height: calc(100% - 70px);
				padding-top: 70px;
				padding-bottom: 0;
			}
			@include mq() {
				height: 600px;
				padding-top: 50px;
			}
			&::after {
				content: "";
				display: block;
				width: 64%;
				height: 100%;
				background: rgba($main-color2, 0.6);
				@include centering;
				z-index: 0;
				@include mq() {
					width: 368px;
				}
			}
			.c-more {
				right: 0;
				margin: auto;
				bottom: 40px;
				z-index: 2;
				@include mq(xs) {
					bottom: 60px;
				}
				@include mq() {
					bottom: 50px;
				}
			}
		}
		.l-content {
			position: relative;
			z-index: 1;
			p {
				@include mq_down(xs) {
					font-size: vwsp(16);
				}
			}
		}
	}
	//- HOME > BUSINESS CONTENT
	&__ourbusiness {
		background: $main-bg-color3;
		padding: 100px 0;
		.l-container {
			color: $main-color2;
			@include mq(xl) {
				display: flex;
				flex-flow: row wrap;
				padding-bottom: 70px;
			}
		}
		.c-head__sc {
			br {
				display: block;
				@include mq() {
					display: none;
				}
			}
		}
		.l-content {
			&__left {
				margin-top: 60px;
				@include mq(xl) {
					width: 50%;
				}
				h3 {
					@include mq(xl) {
						letter-spacing: -0.05em;
					}
				}
			}
			&__right {
				margin-top: 60px;
				@include mq(xl) {
					width: 50%;
				}
			}
		}
		&__content {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 640px;
			@include mq(xl) {
				margin-top: 28px;
			}
			& > div {
				width: 32%;
				max-width: 180px;
				height: 0;
				padding-bottom: 46%;
				background: $main-color2;
				text-align: center;
				position: relative;
				@include mq() {
					height: 258px;
					padding-bottom: 0;
				}
				@include mq(xl) {
					width: 160px;
					height: 240px;
				}
				figure {
					width: 40%;
					height: 27%;
					margin-top: 20%;
					margin-left: auto;
					margin-right: auto;
					@include mq(xl) {
						width: 45%;
						margin-top: 25%;
					}
				}
				.c-head__bar {
					font-family: $font-family-base;
					font-size: vwsp(15);
					line-height: 1.3;
					letter-spacing: -0.05em;
					margin-top: calc(53% + 1.2em + 2px);
					@include mq(sm) {
						@include font-size(16);
						letter-spacing: -0.1em;
						margin-top: calc(60% + 1.2em + 2px);
					}
					@include mq() {
						margin-top: calc(42px + 1.2em);
					}
					@include mq(xl) {
						margin-top: calc(36px + 1.2em);
					}
					&::after {
						width: 1.5em;
						margin: 5px auto 0;
					}
				}
				p {
					color: $main-color5;
					font-family: $font-family-ttl-en;
					font-weight: $font-weight-bolder;
					@include absolute_bottom_center($bottom: 2em);
					@include mq_down() {
						font-size: vwsp(15);
						letter-spacing: -0.05em;
						bottom: 0.5em;
					}
					@include mq(xl) {
						bottom: 7%;
					}
				}
				&:nth-of-type(3) {
					.c-head__bar {
						margin-top: calc(53% + 2px);
						@include mq(sm) {
							margin-top: calc(60% + 2px);
						}
						@include mq() {
							margin-top: 42px;
						}
						@include mq(xl) {
							margin-top: 36px;
						}
					}
				}
			}
		}
	}
	//- HOME > COMPANY PROFILE
	&__companyprofile {
		position: relative;
		height: 0;
		padding-bottom: vwsp(630);
		@include bg("home/bg_companyprofile_s.jpg", no, cc, cover);
		overflow: hidden;
		@include mq(xs) {
			padding-bottom: 0;
			height: vwsize(1866);
		}
		@include mq(sm) {
			height: vwsize(1200);
			background-image: url($imgDir + "home/bg_companyprofile.jpg");
		}
		@include mq(lg) {
			padding-top: 100px;
			padding-bottom: 100px;
			height: 800px;
		}
		&::after {
			content: "";
			display: block;
			width: vwsp(310);
			height: 100%;
			background: rgba($main-color, 0.3);
			@include absolute_top_left;
			z-index: 0;
			@include mq(sm) {
				width: vwsize(800);
			}
			@include mq() {
				width: vwsize(750);
			}
			@include mq(lg) {
				width: calc(400px + 1vw);
			}
			@include mq(xl) {
				width: 460px;
			}
			@include mq(xxl) {
				width: 100%;
				left: -280px;
				transform: translate(-50%, 0);
			}
		}
		.l-container {
			height: 0;
			padding-bottom: 100%;
			padding-top: 10%;
			z-index: 1;
			@include mq(xs) {
				padding-top: 8%;
				padding-bottom: 0;
				height: 83%;
			}
			@include mq(sm) {
				padding-top: 0;
				height: vwsize(840);
			}
			@include mq() {
				padding-top: vwsize(40);
			}
			@include mq(lg) {
				height: 680px;
				padding-top: 60px;
			}
			&::before {
				content: "";
				display: block;
				width: calc(50% + 6vw);
				height: 100%;
				background: rgba($main-color, 0.4);
				@include absolute_top_left($left: -6vw);
				@include mq_down(xs) {
					width: vwsp(230);
				}
				@include mq(sm) {
					width: vwsize(680);
					height: 110%;
					top: -10%;
				}
				@include mq() {
					width: vwsize(600);
					top: -(vwsize(40));
				}
				@include mq(lg) {
					width: 320px;
					height: 600px;
					top: 0;
					left: -2vw;
				}
				@include mq(xl) {
					width: 340px;
				}
			}
			.c-more {
				bottom: 11%;
				@include mq() {
					bottom: 5%;
				}
				@include mq() {
					bottom: 150px;
				}
			}
		}
		.l-content {
			position: relative;
			z-index: 2;
			.c-head__sc {
				br {
					@include mq_down(xl) {
						display: block;
					}
				}
			}
		}
	}
}

_:-ms-lang(x)::-ms-backdrop,
.p-top__mainvisual__bg::before {
	opacity: 0.1;
}
