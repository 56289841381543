@charset "utf-8";

/* ---------------------------------------------
	Address
--------------------------------------------- */
address {
	font-weight: $font-weight-bolder;
	span {
		display: block;
		font-weight: $font-weight-base;
	}
}
