@charset "utf-8";

/* ---------------------------------------------
	Header
--------------------------------------------- */
.l-header {
	width: 100%;
	height: 100px;
	z-index: 11;
	background: rgba($main-bg-color, 0.7);
	transition: 0.5s ease-in-out;
	position: fixed;
	top: 0;
	z-index: 1;
	transition: all 1s;

	@include mq_down(sm) {
		height: vwsp(82);
	}

	@include mq(xl) {
		height: 140px;
	}

	@include mq(xxl) {
		height: 160px;
	}

	&__inner {
		width: calc(100% - 4vw);
		max-width: 1340px;
		height: 40px;
		margin-top: 35px;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		z-index: 11;
		@include mq_down(sm) {
			height: vwsp(32);
			margin-top: vwsp(30);
		}

		@include mq(xl) {
			height: 45px;
			margin-top: 50px;
		}

		@include mq(xxl) {
			height: 50px;
			margin-top: 60px;
		}
	}

	&__logo {
		width: 56vw;
		max-width: 324px;
		height: 100%;
		max-height: 50px;
		margin-left: 0;
		line-height: 0.5;
		position: relative;
		z-index: 10;
		transition: all 0.8s;

		@include mq(xl) {
			width: 365px;
			max-width: 365px;
			height: 45px;
		}

		@include mq(xxl) {
			width: 405px;
			max-width: 405px;
			height: 50px;
		}

		a {
			@include over;

			svg {
				display: inline;
				width: 100%;
				max-width: 324px;
				height: auto;
				margin-left: 0;

				@include mq(xl) {
					max-width: 365px;
				}

				@include mq(xxl) {
					max-width: 405px;
				}
			}
		}
		.open & {
			z-index: 0;
		}
	}
}
