@charset "utf-8";

/* ---------------------------------------------
	Thanks
--------------------------------------------- */
.p-thanks {
	main {
		min-height: 700px;
		@include bg("common/minatomirai.jpg", no, ct, cover);
	}
}
.c-thanks {
	padding-top: 70px !important;
	&__wrap {
		max-width: 700px;
		padding: 50px 6% 30px;
		margin: auto;
		text-align: center;
		position: relative;
		background: $main-color2;
		border-radius: 0;
	}
	&__close {
		@include absolute_top_right($top: -65px);
		a {
			color: $main-color;
			@include font-size(30);
			font-weight: $font-weight-light;
		}
	}
	&__header {
		h2 {
			line-height: 1.6;
		}
	}
	&__message {
		p {
			@include font-size(14);
			line-height: 2;
			@include mq_down(xs) {
				font-size: vwsp(14);
			}
		}
	}
	&__footer {
		margin-top: 3em;
		p {
			line-height: 1.6;
			@include font-size(11);
		}
	}
}
