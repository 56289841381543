@charset "utf-8";

//* =============================================
//#  arrow
//============================================= */
@mixin arrow($color: $main-color, $width: n, $height: n, $right: auto, $left: auto, $top: 0, $rotate: n, $transition: n) {
	display: block;
	position: relative;
	display: inline-block;
	vertical-align: middle;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: $right;
		left: $left;
		width: $width;
		height: $height;
		top: $top;
		margin: auto;
		vertical-align: middle;
		border-top: 2px solid $color;
		border-right: 2px solid $color;
		transform: rotate(#{$rotate}deg);
		transition: $transition + s ease-in-out;
	}
}

//* =============================================
//#  background image
//============================================= */
@mixin img_repl {
	overflow: hidden;
	text-indent: 200%;
	white-space: nowrap;
}

@mixin bg($path, $repeat: no, $pos: lt, $size: n) {
	// ------ パス
	@if $path== "n" {
	} @else if $path== "no" {
	} @else {
		background-image: url(#{$imgDir}#{$path});
	}

	// ------ リピート
	@if $repeat== "no" {
		$repeat: "no-repeat";
	} @else if $repeat== "x" {
		$repeat: "repeat-x";
	} @else if $repeat== "y" {
		$repeat: "repeat-x";
	}

	background-repeat: #{$repeat};

	// ------ ポジション
	@if $pos== "lt" {
		$pos: "left top";
	} @else if $pos== "ct" {
		$pos: "center top";
	} @else if $pos== "rt" {
		$pos: "right top";
	} @else if $pos== "lb" {
		$pos: "left bottom";
	} @else if $pos== "cb" {
		$pos: "center bottom";
	} @else if $pos== "rb" {
		$pos: "right bottom";
	} @else if $pos== "cc" {
		$pos: "center";
	} @else if $pos== "lc" {
		$pos: "left center";
	} @else if $pos== "rc" {
		$pos: "right center";
	}

	background-position: #{$pos};

	// ------ サイズ
	@if $size== "no" {
	} @else if $size== "n" {
	} @else {
		-webkit-background-size: $size;
		-o-background-size: $size;
		background-size: $size;
	}
	@content;
}

@mixin ratio($imgpath, $w, $h) {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: $h / $w * 100%;
	image: url($imgpath);
	background: url(#{$imgDir}#{$imgpath}) no-repeat;
	background-size: 100%;
}

//* =============================================
//#  box-sizing
//============================================= */
@mixin box-sizing($type) {
	box-sizing: $type;
}

//* =============================================
//#  break
//============================================= */
@mixin break {
	&::before {
		content: "\A";
		white-space: pre;
	}
}

//* =============================================
//#  button
//============================================= */
@mixin boxBtn($color, $hover-color) {
	position: relative;
	display: inline-block;
	margin: auto;
	padding: 0.5em 1em;
	text-decoration: none;
	border-radius: 5px;
	background: $color;
	font-size: 21px;
	color: #fff;

	&:hover {
		background: $hover-color;
		transition-duration: 0.3s;
		transition-timing-function: ease-in-out;
	}
}

//* =============================================
//#  clearfix
//============================================= */
@mixin clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

//* =============================================
//#  ellipsis
//============================================= */
@mixin ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

//* =============================================
//#  flexbox
//============================================= */
@mixin flexbox($direction: row, $wrap: wrap, $justify: between) {
	display: flex;

	@if $direction== "column" {
		flex-direction: column;
	} @else if $direction== "row" {
		flex-direction: row;
	} @else if $direction== "column-reverse" {
		flex-direction: column-reverse;
	} @else if $direction== "row-reverse" {
		flex-direction: row-reverse;
	}

	@if $justify== "start" {
		justify-content: flex-start;
	} @else if $justify== "center" {
		justify-content: center;
	} @else if $justify== "end" {
		justify-content: flex-end;
	} @else if $justify== "around" {
		justify-content: space-around;
	} @else if $justify== "between" {
		justify-content: space-between;
	}

	@if $wrap== "wrap" {
		flex-wrap: wrap;
	} @else if $wrap== "nowrap" {
		flex-wrap: nowrap;
	}
	@content;
}

@mixin split($split1: n) {
	@include flexbox(row, wrap, between);
	.c-cell {
		width: calc((100% / #{$split1}));
	}
}

@mixin split_around($split2: n) {
	@include flexbox(row, wrap, between);
	.c-cell {
		width: calc((100% - (15px * #{$split2})) / #{$split2}) !important;
		max-width: calc((100% - (15px * #{$split2})) / #{$split2}) !important;
		margin-bottom: 15px;
		@include mq(lg) {
			width: calc((100% - (20px * #{$split2})) / #{$split2}) !important;
			max-width: calc((100% - (20px * #{$split2})) / #{$split2}) !important;
			margin-bottom: 20px;
		}
	}
}

//* =============================================
//#  font
//============================================= */
@mixin google-font($family) {
	@import url("https://fonts.googleapis.com/css?family=#{$family}&display=swap");
}

@mixin font-size($size: 16) {
	font-size: $size + px;
	font-size: ($size / 10) + rem;
}

@mixin font-step($size: n, $breakpoint: md) {
	$sizel: round($size * 1.27);
	font-size: $size + px;
	font-size: ($size / 10) + rem;
	@include mq($breakpoint) {
		@include font-size($sizel);
	}
}

$viewport_sp: 480;
@function vwsp($num, $width: $viewport_sp) {
	@return (1vw * $num / $width * 100);
}

$viewport: 1480;
@function vwsize($num, $width: $viewport) {
	@return (1vw * $num / $width * 100);
}

//* =============================================
//#  gradient
//============================================= */
@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;

	@if $orientation==vertical {
		// vertical
		background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
		background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
	} @else if $orientation==horizontal {
		// horizontal
		background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: linear-gradient(to right, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
	} @else {
		// radial
		background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
		background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
		background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
	}
}

//* =============================================
//#  hover
//============================================= */
// hover line
@mixin hvr--line($borderColor: #3c699e) {
	display: inline-block;
	vertical-align: middle;
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	backface-visibility: hidden;
	position: relative;
	overflow: hidden;
	transition: all 0.2s ease-in;

	&::before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 100%;
		bottom: 0;
		background: $borderColor;
		height: 1px;
		transition-property: right;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}

	&:hover::before {
		right: 0;
	}
}

//* =============================================
//#  hover
//============================================= */
@mixin hover($touch: false, $highlight: false, $userSelect: false, $focus: true) {
	// mouse
	@at-root .mouse &:hover {
		@content;
	}

	@if $focus==true {
		@at-root .mouse &:focus {
			@content;
		}
	}

	// touch
	@if $touch==true {
		@at-root .touch &:active {
			@content;
		}

		// $highlight
		@if $highlight==false {
			-webkit-tap-highlight-color: rgba(#000, 0);
			appearance: none;
		}

		// $userSelect
		@if $userSelect==false {
			@at-root .touch & {
				user-select: none;

				input,
				select {
					user-select: auto;
				}
			}
		}
	} @else if $touch==false {
	} @else {
		@warn 'mixin hover $touchの値が正しくありません';
	}
}

@mixin hover-focus() {
	@at-root .mouse &:focus {
		@content;
	}

	@at-root .touch &:focus {
		@content;
	}
}

//* =============================================
//#  keyframes
//============================================= */
@mixin keyframes($animation-name) {
	@keyframes #{$animation-name} {
		@content;
	}

	@-ms-keyframes #{$animation-name} {
		@content;
	}

	@-webkit-keyframes #{$animation-name} {
		@content;
	}
}

//* =============================================
//#  list
//============================================= */
@mixin list-dl($dtwidth: n, $space: n) {
	@include flexbox(row, wrap, start);
	dt {
		width: $dtwidth;
	}

	dd {
		width: calc(100% - #{$dtwidth});
		padding-left: $space;
	}
}

//* =============================================
//#  position
//============================================= */
//centering
@mixin centering {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// absolute
@mixin over {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto, $zindex: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	z-index: $zindex;
}

@mixin absolute_top_left($top: 0, $left: 0, $zindex: 0) {
	position: absolute;
	top: $top;
	left: $left;
	z-index: $zindex;
}

@mixin absolute_top_right($top: 0, $right: 0, $zindex: 0) {
	position: absolute;
	top: $top;
	right: $right;
	z-index: $zindex;
}

@mixin absolute_bottom_left($bottom: 0, $left: 0, $zindex: 0) {
	position: absolute;
	bottom: $bottom;
	left: $left;
	z-index: $zindex;
}

@mixin absolute_bottom_right($bottom: 0, $right: 0, $zindex: 0) {
	position: absolute;
	bottom: $bottom;
	right: $right;
	z-index: $zindex;
}

@mixin absolute_top_center($top: 0, $right: 0, $left: 0, $zindex: 0) {
	position: absolute;
	top: $top;
	right: $right;
	left: $left;
	margin: auto;
	z-index: $zindex;
}

@mixin absolute_bottom_center($bottom: 0, $right: 0, $left: 0, $zindex: 0) {
	position: absolute;
	bottom: $bottom;
	right: $right;
	left: $left;
	margin: auto;
	z-index: $zindex;
}

@mixin absolute_center_left($top: 0, $bottom: 0, $left: 0, $zindex: 0) {
	position: absolute;
	top: $top;
	bottom: $bottom;
	left: $left;
	margin: auto;
	z-index: $zindex;
}

@mixin absolute_center_right($top: 0, $bottom: 0, $right: 0, $zindex: 0) {
	position: absolute;
	top: $top;
	bottom: $bottom;
	right: $right;
	margin: auto;
	z-index: $zindex;
}

//* =============================================
//#  size
//============================================= */
@mixin size($width, $height) {
	width: $width;
	height: $height;
}

//circle
@mixin circle($size) {
	@include size($size, $size);
	border-radius: 50%;
}
