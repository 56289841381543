@charset "utf-8";
// =================================================
// Setting
// =================================================
// width -------------------------------------------
$width-browser: 1600px;
$width-base: 1340px;
$width-main: 1280px;
$width-content: 640px;

// font --------------------------------------------
$font-family-base: "Noto Sans JP", sans-serif;
$font-family-mincho: "Shippori Mincho", serif;
$font-family-en: "Josefin Sans", sans-serif;
$font-family-numeric: "Poppins", sans-serif;
$font-family-ttl-en: "Syncopate", sans-serif;
$font-size-base: 15;
$font-family-monospace: monospace, serif;
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$font-weight-ttl-en: 800;
$letter-spacing: 0.015em;
$line-height-base: 2;

// color -------------------------------------------
$main-color: #000;
$main-color2: #fff;
$main-color3: #999; // コピーライト
$main-color4: #231815; // サイド見出し
$main-color5: #bbb; // サイド見出し英字、フッターCONTACTボタン下TEL・E-MAIL

$main-bg-color: #fff;
$main-bg-color2: #f1f1f1; // リンクエリア背景
$main-bg-color3: #262626; // フッター背景、トップセクション背景

$accent-color: #890200; // コンテンツリード文字
$accent-color2: #920e14; // コンテンツ内強調文字

$contact-mds-bg-color: #191919;

$btn-nav-color: #890200;
$btn-contact-bg-color: #999;

$line-color: rgba(#231815, 0.4); // ドットライン
$line-color2: #000; // NEWSドットライン

$selection-color: #bbbbbb;
$scrollbar-face-color: #999;

// form color -------------------------------------------
$wht: #fff;
$blk: #000;
$inputbrdr: #f8f8f8;
$inputbk: #f8f8f8;
$box-shadow: #999;
$colored: #efefef;
$achroma: #f8f8f8;
$placeholder: #999;
$wrn: #edd5de;
$must: #890200;
$err: #990000;
$ok: #0c0;
$submit1: #951c1a;
$submit2: #ad1c1c;
$submit_txt: #fff;
$reset1: #fff;
$reset2: #1e1e1e;

// link --------------------------------------------
$link-color: #000;
$link-font-weight: 400;
$link-decoration: none;
$link-hover-color: #999;
$link-hover-decoration: underline;

// table -------------------------------------------
$table-cell-padding: 1em;
$table-caption-color: #000;

// margin ------------------------------------------
$dt-font-weight: 300;
$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;
$label-margin-bottom: 0;

// other -------------------------------------------
$enable-pointer-cursor-for-buttons: true;
$imgDir: "../images/";
